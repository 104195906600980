<template>
        <v-text-field
            :append-icon="Show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="Rules"
            :hint="Message"
            :type="Show ? 'text' : 'password'"
            :value="Value"
            :label="Label"
            outlined
            counter
            @input="Fn($event)"
            @keyup.enter="Enter()"
            @click:append="showAndHidePassword"
            :dark="Dark"
        />

</template>
<script>
export default {
    data() {
        return {
            Show: false
        }
    },
    methods: {
        showAndHidePassword(){
            this.Show = !this.Show;
        }
    },
    props:{
        Rules: Array,       //Rules for input's text
        Message: String,    //This message is visible when you not follow the rules
        Value: String,      //Is the input's value
        Label: String,      //Is the input's name
        Fn: Function,       //Funtion to do when the users change the input's value
        Enter: Function,    //Funtion used when push enter button
        Dark: Boolean       // Dark mode
    }
}
</script>