<template>

    <v-text-field
        :value="Value"
        :label="Label"
        :rules='Rules'
        :type="Type"
        outlined
        @change="Fn($event)"
        @keypress.enter="Enter()"  
        :dark='Dark'
        :disabled="Disabled"
    ></v-text-field>
</template>
<script>
export default {
    name: 'TextFields',
    props:{
        Dark:    Boolean, //Define if the field must be dark or not
        Rules:   Array,  //Is an array of rules to fill the text field
        Value:   String, //Is the text or number in the field
        Label:   String, //Is the input's name
        Type:    String, //Is the type of text accepted for the field
        Fn:      Function, //This function is used to update the value prop
        Enter:   Function ,//Funtion to do when the users press enter key
        Disabled: Boolean //Define if the field is disabled or not
    }
}
</script>